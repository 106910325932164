var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('TabTitle', {
    staticClass: "mb-4",
    attrs: {
      "icon": "fa-file-contract"
    },
    scopedSlots: _vm._u([{
      key: "sub-title",
      fn: function () {
        return [_vm._v("Upload an LOA and link it to an account.")];
      },
      proxy: true
    }])
  }, [[_vm._v("LOA Management")]], 2), _c('div', {
    staticClass: "block block-rounded block-fx-pop"
  }, [_c('div', {
    staticClass: "block-content block-content-full"
  }, [_vm.error ? _c('div', {
    staticClass: "alert alert-danger"
  }, [_vm._v(_vm._s(_vm.error))]) : _vm._e(), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-md-4"
  }, [_vm.assetsLoading.list ? _c('Spinner', {
    attrs: {
      "size": "md"
    }
  }) : _c('FormGroup', {
    staticClass: "mb-0",
    attrs: {
      "id": "gridfetch-asset",
      "type": "select2",
      "config": {
        allowClear: true,
        allowSearch: true
      },
      "options": _vm.assetOptions,
      "placeholder": "Select an Asset..."
    },
    on: {
      "input": _vm.onSelectAsset
    },
    model: {
      value: _vm.assetId,
      callback: function ($$v) {
        _vm.assetId = $$v;
      },
      expression: "assetId"
    }
  }), _vm.assetId ? _c('div', {
    staticClass: "mt-3"
  }, [_vm.accountsLoading.list ? _c('Spinner', {
    attrs: {
      "size": "md"
    }
  }) : _c('FormGroup', {
    staticClass: "mb-0",
    attrs: {
      "id": "gridfetch-account",
      "type": "select2",
      "config": {
        allowClear: true,
        allowSearch: true
      },
      "options": _vm.accountOptions,
      "placeholder": "Select an Account..."
    },
    model: {
      value: _vm.accountId,
      callback: function ($$v) {
        _vm.accountId = $$v;
      },
      expression: "accountId"
    }
  })], 1) : _vm._e()], 1)])])]), _vm.accountId ? _c('div', {
    staticClass: "block block-rounded block-fx-pop"
  }, [_c('div', {
    staticClass: "block-content block-content-full"
  }, [_c('h4', {
    staticClass: "mb-3"
  }, [_vm._v("LOA Upload")]), _vm.selectedAccountMpan ? _c('p', {
    staticClass: "mb-3"
  }, [_c('strong', [_vm._v("Selected MPAN:")]), _vm._v(" " + _vm._s(_vm.selectedAccountMpan))]) : _vm._e(), _c('FormGroup', {
    staticClass: "mb-0",
    attrs: {
      "id": "gridfetch-loa-date",
      "label": "Expiry Date",
      "type": "datePicker",
      "placeholder": "Select a date"
    },
    model: {
      value: _vm.expiryDate,
      callback: function ($$v) {
        _vm.expiryDate = $$v;
      },
      expression: "expiryDate"
    }
  }), _c('FileUpload', {
    staticClass: "bg-light mb-3 mt-3",
    attrs: {
      "progress": _vm.gridfetch.loaUploadProgress,
      "error": _vm.error,
      "loading": _vm.uploading,
      "file-types": ['pdf', 'docx']
    },
    model: {
      value: _vm.file,
      callback: function ($$v) {
        _vm.file = $$v;
      },
      expression: "file"
    }
  }), _vm.gridfetch.loaUrl ? _c('p', {
    staticClass: "mb-3"
  }, [_c('strong', [_vm._v("URL (For debug):")]), _vm._v(" " + _vm._s(_vm.gridfetch.loaUrl))]) : _vm._e(), _c('button', {
    staticClass: "btn btn-primary",
    class: {
      disabled: !_vm.file.file
    },
    attrs: {
      "disabled": !_vm.file.file
    },
    on: {
      "click": _vm.onClickUpload
    }
  }, [_vm._v("Upload to Gridfetch")])], 1)]) : _vm._e(), _c('div', {
    staticClass: "block block-rounded block-fx-pop"
  }, [_c('div', {
    staticClass: "block-content block-content-full"
  }, [_c('DataTable', {
    attrs: {
      "items": _vm.loaAccounts.data,
      "total": _vm.loaAccounts.total,
      "loading": _vm.loaAccountsLoading,
      "columns": _vm.loaAccountsColumns,
      "page-size": 20,
      "fetch-items": _vm.listAccounts,
      "default-sort-key": "uploadedAt",
      "default-sort-direction": -1,
      "other-params": _vm.loaAccountsParams
    },
    scopedSlots: _vm._u([{
      key: "gridfetchLoa.expiryDate",
      fn: function (slotProps) {
        return [_vm._v(" " + _vm._s(_vm._f("date")(slotProps.data.gridfetchLoa.expiryDate, 'Do MMM YYYY HH:mm:ss')) + " ")];
      }
    }, {
      key: "uploadedBy",
      fn: function (slotProps) {
        return [_vm._v(" " + _vm._s(_vm._f("user")(slotProps.data.gridfetchLoa.uploadedBy, _vm.users)) + " ")];
      }
    }, {
      key: "uploadedAt",
      fn: function (slotProps) {
        return [_vm._v(" " + _vm._s(_vm._f("date")(slotProps.data.gridfetchLoa.uploadedAt, 'Do MMM YYYY HH:mm:ss')) + " ")];
      }
    }])
  })], 1)])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }